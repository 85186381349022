import { Routes } from "@angular/router";
import { AuthGuardsService } from "./core";

export const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuardsService],
    loadChildren: () => import("./modules").then((m) => m.approutes),
  },
  {
    path: "auth",
    loadChildren: () => import("./modules").then((m) => m.authroutes),
  },
];
